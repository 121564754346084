import { noop } from "lodash";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import { AppLayoutProps } from "./AppLayout";

interface AppLayoutContextProps extends AppLayoutProps {
  readonly changeProps: (props: AppLayoutProps) => void;
}

function createContentValue(): AppLayoutContextProps {
  return {
    changeProps: noop,
  };
}

export const AppLayoutContext = React.createContext<AppLayoutContextProps>(createContentValue());

interface Props {
  readonly children: ReactNode;
}

export function AppLayoutProvider(props: Props) {
  const [appLayoutProps, setAppLayoutProps] = useState<AppLayoutProps>({});
  const changeAppProps = (props: AppLayoutProps) => {
    setAppLayoutProps((prev) => ({ ...prev, ...props }));
  };
  return (
    <AppLayoutContext.Provider
      {...props}
      value={{
        ...appLayoutProps,
        changeProps: changeAppProps,
      }}
    />
  );
}

export function useAppLayout(): AppLayoutContextProps {
  return useContext(AppLayoutContext);
}

export function useInitialLayoutProps(props: AppLayoutProps) {
  const appLayout = useAppLayout();

  useEffect(() => {
    appLayout.changeProps(props);
  }, []);
}
