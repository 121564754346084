import Typography from "@mui/material/Typography";
import { makeStyles } from "../../theme/Theme";
import React, { useEffect, useMemo, useState } from "react";
import { Box } from "../ui/Box";
import { Stack } from "@mui/material";
import { Checkbox } from "../checkbox/Checkbox";
import { ActionButton } from "../ui/ActionButton";
import { Breakpoints } from "../../dto/ApplicationDTO";
import {
  strategyApi,
  useConfirmSmsBuyPromoStrategyMutation,
  useGenerateSmsBuyPromoStrategyMutation,
  useGetErrorByStrategyQuery,
  useLazyStrategyAgreementListQuery,
  useSaveDataWithErrorByStrategyMutation,
  useStrategyPromoListQuery,
} from "../../api/strategyApi";
import { BackElTop } from "../ui/BackElTop";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import { UniversalSubmitCodeBackdrop } from "../ui/Sidebar/UniversalSubmitCodeBackdrop";
import { SkeletonDocItems } from "./SkeletonDocItems";
import { useGenerateSurveyCodeMutation, useSendSurveyDataMutation } from "../../api/surveyApi";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";
import { systemIsDemoModeSelector } from "../../api/profileApi";
import { openSnackbarError } from "../../utils/NotificationsUtils";
import { AppLayoutFooter } from "../app-layout/AppLayoutFooter";
import { Spacer } from "../ui/Spacer";
import { useAppDispatch } from "../../hooks/useRedux";
import { ViewSurveyPreview } from "./ViewSurveyPreview";
import { useAppLayout } from "../app-layout/AppLayoutProvider";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.total.main,
    marginBottom: 20,
  },
  wrapperContent: {
    padding: 20,
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      padding: 12,
    },
    marginTop: 20,
    borderRadius: 16,
    boxShadow: "0px 4px 10px rgba(74, 81, 112, 0.05)",
    backgroundColor: theme.palette.walter.main,
  },
  contentTitle: {
    color: theme.palette.total.main,
  },
  contentDescription: {
    color: theme.palette.total.main,
    whiteSpace: "pre-wrap",
  },
  wrapperStackCheckbox: {
    marginTop: 30,
    gap: 12,
  },
  actionBtn: {
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      width: "100%",
    },
    [theme.breakpoints.up(Breakpoints.Tablet)]: {
      width: "141px",
    },
  },
  contentCheckbox: {
    display: "flex",
    alignItems: "center",
  },
  linkContract: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
}));

interface Props {
  selectContract: (item?: Partial<StrategiesAgreementArray>) => void;
}

const getTitleByNameAgreement = (name: keyof Strategy.AgreementContractBuyStrategy | "") => {
  switch (name) {
    case "investmentDeclaration":
      return "инвестиционной декларацией";
    case "termsAgreement":
      return "условиями договора";
    case "termsOffer":
      return "условиями оферты";
    default:
      return "";
  }
};

export interface StrategiesAgreementArray extends Strategy.Agreement {
  key: keyof Strategy.AgreementContractBuyStrategy;
  text: string;
  strategyId: string;
}

const agreementToArray = (
  strategyId: string,
  agreement?: Strategy.AgreementContractBuyStrategy,
): Array<Partial<StrategiesAgreementArray>> => {
  if (!agreement) return [];
  const keyNames = Object.keys(agreement) as Array<
    Partial<keyof Strategy.AgreementContractBuyStrategy>
  >;
  const agreements: Array<Partial<StrategiesAgreementArray>> = [];
  keyNames.forEach((key) => {
    agreements.push({
      text: getTitleByNameAgreement(key),
      key: key,
      ...agreement[key],
      strategyId,
    });
  });
  return agreements;
};
type DefaultProps = {
  routeBack: AppRoutes;
  search?: string;
};
type FixPersonalDataProps = DefaultProps & {
  type: ActionDocumentMov.PERSONAL_DATA;
  stateData: Strategy.SaveFixPersonalData;
};
export type BuyStrategyDataProps = DefaultProps & {
  strategyId: string;
  type: ActionDocumentMov.BUY_STRATEGY;
  stateData: {
    fields: Survey.SaveField[];
    stepId: number | undefined;
    strategyId: string;
  };
};

export enum ActionDocumentMov {
  BUY_STRATEGY = "buyStrategy",
  PERSONAL_DATA = "personalData",
}

export enum TypeTransitionNextScreenAfterDocument {
  SUCCESS_FIX_PERSONAL_DATA = "success_fix_personal_data",
  SUCCESS_BUY_STRATEGY = "success_buy_strategy",
}

export type PropsDocumentFromOtherScreen = FixPersonalDataProps | BuyStrategyDataProps;
export const DocumentLookContractByStrategy = ({ selectContract }: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const appLayout = useAppLayout();
  const isDemo = useShallowEqualSelector(systemIsDemoModeSelector);
  const dispatch = useAppDispatch();

  const [saveDataWithErrorByStrategy, { isLoading: saveFixPersonalDataLoading }] =
    useSaveDataWithErrorByStrategyMutation();
  const [generateSms, { isLoading: isLoadingGenerateSms }] =
    useGenerateSmsBuyPromoStrategyMutation();
  const [signSms] = useConfirmSmsBuyPromoStrategyMutation();
  const strategyPromoList = useStrategyPromoListQuery();
  const strategyErrorBuy = useGetErrorByStrategyQuery();
  const [generateSurveyCode] = useGenerateSurveyCodeMutation();
  const [sendSurveyDataMutation] = useSendSurveyDataMutation();
  const stateFromPrevScreen = location.state as PropsDocumentFromOtherScreen;

  const idStrategy =
    stateFromPrevScreen.type === ActionDocumentMov.BUY_STRATEGY
      ? stateFromPrevScreen?.stateData?.strategyId
      : strategyErrorBuy?.data?.data?.idStrategy;
  const [
    getStrategyAgreementList,
    { data: agreementList, isLoading: isLoadingAgreementList, error: agreementListError },
  ] = useLazyStrategyAgreementListQuery();

  const contractBuyStrategy = agreementList?.data;
  const agreementToArrayState = useMemo(
    () => agreementToArray(idStrategy || "", contractBuyStrategy),
    [contractBuyStrategy, idStrategy],
  );
  const confirmLoadingBtn = saveFixPersonalDataLoading || isLoadingGenerateSms;

  const [allowConfirmAgreement, setAllowConfirmAgreement] = useState<
    Partial<Record<keyof Strategy.AgreementContractBuyStrategy, boolean>>
  >({});
  const [isOpenSendCodeModal, setIsOpenSendCodeModal] = useState<boolean>(false);
  const [idActionPersonalDataForCode, setIdActionPersonalDataForCode] = useState<string>("");
  const [errorPage, setErrorPage] = useState<boolean>(!!agreementListError);

  const description = errorPage
    ? `Из-за технических проблем подтверждение условий договора временно невозможно. \n Пожалуйста, не уходите со страницы и нажмите на кнопку “обновить” через некоторое время, \n чтобы продолжить процесс заключения договора.`
    : `Для подтверждения согласия с условиями договора необходимо ввести код из СМС, отправленного на Ваш номер телефона. \nЕсли у вас возникли вопросы, обратитесь к вашему инвестиционному советнику.`;
  const isDisabledBtnConfirm = useMemo(() => {
    const checkbox = Object.keys(allowConfirmAgreement);
    const checkboxValues = Object.values(allowConfirmAgreement);
    if (checkbox.length === 0) return true;
    return checkboxValues.some((val) => !val);
  }, [allowConfirmAgreement]);

  const goBack = () => {
    navigate(stateFromPrevScreen?.routeBack + (stateFromPrevScreen?.search ?? ""), {
      state: {
        ...stateFromPrevScreen,
        routeBack: AppRoutes.DocumentBuyStrategyLink,
        ...stateFromPrevScreen?.stateData,
      },
    });
  };

  const toggleCheckbox = (keyName?: keyof Strategy.AgreementContractBuyStrategy) => {
    if (!keyName) return;
    setAllowConfirmAgreement((prev) => {
      const prevValue = !prev[keyName];
      return {
        ...prev,
        [keyName]: prevValue,
      };
    });
  };
  const closeModalSendCode = () => {
    setIsOpenSendCodeModal(false);
    setIdActionPersonalDataForCode("");
  };
  const onResendCode = (_actionId?: string) => {
    if (!stateFromPrevScreen) {
      goBack();
      return Promise.reject();
    }
    if (stateFromPrevScreen.type === ActionDocumentMov.PERSONAL_DATA) {
      return generateSms({ actionId: _actionId ?? idActionPersonalDataForCode })
        .unwrap()
        .then(() => {
          setIsOpenSendCodeModal(true);
        })
        .catch((e) => {
          openSnackbarError(e);
        });
    }
    if (stateFromPrevScreen.type === ActionDocumentMov.BUY_STRATEGY) {
      return generateSurveyCode()
        .unwrap()
        .then(() => {
          setIsOpenSendCodeModal(true);
        })
        .catch((e) => {
          openSnackbarError(e);
        });
    }

    return Promise.reject();
  };
  const confirmAgreement = async () => {
    if (!stateFromPrevScreen) {
      goBack();
      return Promise.reject();
    }
    if (stateFromPrevScreen.type === ActionDocumentMov.PERSONAL_DATA) {
      try {
        const data = await saveDataWithErrorByStrategy(stateFromPrevScreen.stateData).unwrap();
        setIdActionPersonalDataForCode(data?.data?.actionId ?? "");
        onResendCode(data.data?.actionId ?? "");
      } catch (e) {
        return openSnackbarError(e);
      }
    }
    if (stateFromPrevScreen.type === ActionDocumentMov.BUY_STRATEGY) {
      return onResendCode();
    }

    return Promise.reject();
  };
  const signOperation = async (code: string): Promise<any> => {
    if (!stateFromPrevScreen) {
      goBack();
      return Promise.reject();
    }
    if (stateFromPrevScreen.type === ActionDocumentMov.PERSONAL_DATA) {
      try {
        await signSms({ actionId: idActionPersonalDataForCode, code }).unwrap();
        navigate(AppRoutes.ProfileLink, {
          state: {
            typeSidebar: TypeTransitionNextScreenAfterDocument.SUCCESS_FIX_PERSONAL_DATA,
          },
        });
      } catch (e) {
        return openSnackbarError(e);
      }
    }
    if (stateFromPrevScreen.type === ActionDocumentMov.BUY_STRATEGY) {
      const { stepId, fields, strategyId } = stateFromPrevScreen.stateData;
      try {
        await sendSurveyDataMutation({
          finalize: true,
          code,
          fields: fields,
          stepId: stepId ?? 0,
          strategyId: parseInt(strategyId),
        }).unwrap();
        dispatch(strategyApi.util?.resetApiState());
        navigate(isDemo ? AppRoutes.DashboardBriefcaseLink : AppRoutes.DashboardStrategiesLink, {
          state: {
            typeSidebar: TypeTransitionNextScreenAfterDocument.SUCCESS_BUY_STRATEGY,
          },
        });
      } catch (e) {
        return openSnackbarError(e);
      }
    }
    return Promise.reject();
  };
  const isHaveAllDocument = (agreements: Strategy.AgreementContractBuyStrategy) =>
    Object.values(agreements).every((agreement) => agreement.linkPdf && agreement.title);
  const handleRetry = () => {
    try {
      const prevErrorPage = errorPage;
      getStrategyAgreementList({ strategyId: idStrategy ?? "" })
        .unwrap()
        .then((agreements) => {
          if (agreements?.data && !isHaveAllDocument(agreements.data) && prevErrorPage) {
            openSnackbarError(undefined);
          }
        })
        .catch((e) => {
          openSnackbarError(e);
        });
    } catch (error) {
      openSnackbarError(error);
    }
  };

  const titleTreaty = useMemo(() => {
    if (
      stateFromPrevScreen.type === ActionDocumentMov.BUY_STRATEGY &&
      stateFromPrevScreen.stateData?.strategyId
    ) {
      const nameStrategy = strategyPromoList?.data?.data?.find(
        (strategy) => strategy.id === stateFromPrevScreen.stateData?.strategyId,
      )?.title;
      if (!nameStrategy) return "Договор";
      return `Договор на покупку инвестиционной декларации “${nameStrategy}”`;
    } else {
      return "Договор";
    }
  }, [stateFromPrevScreen, strategyPromoList?.data?.data]);

  useEffect(() => {
    const objStateCheckbox = {} as Record<keyof Strategy.AgreementContractBuyStrategy, boolean>;
    for (const agreement in contractBuyStrategy) {
      objStateCheckbox[agreement] = false;
    }
    setAllowConfirmAgreement(objStateCheckbox);
  }, [contractBuyStrategy]);
  useEffect(() => {
    if (agreementList?.data) {
      const isAllHaveDocument = isHaveAllDocument(agreementList?.data);
      setErrorPage(!isAllHaveDocument);
    } else {
      setErrorPage(true);
    }
  }, [agreementList]);
  useEffect(() => {
    getStrategyAgreementList({ strategyId: idStrategy ?? "" });
  }, [idStrategy]);
  useEffect(() => {
    appLayout.changeProps({ showViewPreviewData: true });
    return () => {
      appLayout.changeProps({ showViewPreviewData: false });
    };
  }, []);

  return (
    <div className={styles.root}>
      <BackElTop onBack={goBack} />
      <ViewSurveyPreview />
      <Typography variant="h1">{titleTreaty}</Typography>
      <Box className={styles.wrapperContent}>
        <Typography variant={"h2"} className={styles.contentTitle} sx={{ marginBottom: "15px" }}>
          Подтверждение условий договора
        </Typography>
        {!isLoadingAgreementList && (
          <Typography variant={"text2"} className={styles.contentDescription}>
            {description}
          </Typography>
        )}
        <Stack className={styles.wrapperStackCheckbox}>
          {isLoadingAgreementList ? (
            <SkeletonDocItems />
          ) : (
            !errorPage &&
            agreementToArrayState.map((agreement) => (
              <div key={agreement.key} className={styles.contentCheckbox}>
                <Checkbox
                  checked={agreement?.key ? allowConfirmAgreement[agreement?.key] : false}
                  onClick={() => toggleCheckbox(agreement?.key)}
                />
                <Typography variant={"text2"}>
                  Я соглашаюсь с документом -{" "}
                  <Typography
                    onClick={() => selectContract(agreement)}
                    variant={"link1"}
                    className={styles.linkContract}
                  >
                    {agreement.title}
                  </Typography>
                </Typography>
              </div>
            ))
          )}
        </Stack>
        <Spacer size={30} />
        <ActionButton
          disabled={isDisabledBtnConfirm && !errorPage}
          loading={confirmLoadingBtn}
          onClick={errorPage ? handleRetry : confirmAgreement}
          className={styles.actionBtn}
        >
          {errorPage ? "Обновить" : "Подтвердить"}
        </ActionButton>
      </Box>
      <UniversalSubmitCodeBackdrop
        onSubmit={signOperation}
        onClose={closeModalSendCode}
        onResend={onResendCode}
        show={isOpenSendCodeModal}
      />
      <Spacer sx={{ flex: 1 }} />
      <AppLayoutFooter />
    </div>
  );
};
