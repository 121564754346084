import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import { formatResendButtonTitle, hidePhoneNumbers } from "../../../helpers/FormatHelpers";
import { useResendCode } from "../../../hooks/useResendCode";
import { useIsTabletUp, useTheme } from "../../../theme/Theme";
import { maskPhoneNumber } from "../../../utils";
import { ActionButton, ButtonType } from "../ActionButton";
import { CodeInput } from "../CodeInput";
import { CloseIcon } from "../../icons/CloseIcon";
import { Backdrop } from "../../sidebar/Backdrop";
import { SidebarBase } from "../../sidebar/SidebarBase";
import { useShallowEqualSelector } from "../../../hooks/useShallowEqualSelector";
import { profilePhoneSelector } from "../../../api/profileApi";

interface Props {
  readonly phoneNumber?: string;
  readonly onSubmit: (code: string) => Promise<any>;
  readonly onResend: () => Promise<void>;
  readonly onClose: () => void;
  readonly show?: boolean;
}

interface BodySendCodeProps extends Props {
  phoneNumber?: string;
  onClose: () => void;
}

const BodySendCode = ({ phoneNumber, onClose, onResend, onSubmit }: BodySendCodeProps) => {
  const theme = useTheme();
  const phoneUsers = useShallowEqualSelector(profilePhoneSelector({ hidden: true, masked: true }));
  const submitPhone = phoneNumber ? hidePhoneNumbers(maskPhoneNumber(phoneNumber)) : phoneUsers;
  const [time, resendCode] = useResendCode();
  const [loading, setLoading] = useState(false);

  const resendHandler = useCallback(async () => {
    setLoading(true);
    return onResend()
      .then(() => resendCode.reset())
      .catch(null)
      .finally(() => setLoading(false));
  }, []);

  const confirmHandler = async (value: string) => {
    if (loading) return;
    setLoading(true);
    return onSubmit(value)
      .catch(null)
      .finally(() => setLoading(false));
  };

  const buttonTitle = formatResendButtonTitle(time, {
    formatWaitTitle: (x) => `Новый код через ${x}`,
  });

  useEffect(() => {
    resendCode.reset();
  }, []);
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h1" color={theme.palette.total.main}>
          Введите код из СМС
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Typography variant="text2" color={theme.palette.total.main}>
        Мы отправили СМС с кодом на Ваш номер: <br />
        {submitPhone}
      </Typography>
      <CodeInput
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "flex-start",
          },
          mt: {
            xs: "20px",
            sm: "25px",
          },
        }}
        onComplete={confirmHandler}
        autoFocus={true}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "flex-start",
          },
          width: "100%",
          mt: { xs: "20px", sm: "25px" },
          mb: { xs: "40px", sm: "0px" },
        }}
      >
        <ActionButton
          sx={{
            width: "100%",
            maxWidth: "310px",
          }}
          buttonType={ButtonType.Primary}
          onClick={resendHandler}
          loading={loading}
          disabled={time > 0}
        >
          <Typography>{buttonTitle}</Typography>
        </ActionButton>
      </Box>
    </Box>
  );
};

export function UniversalSubmitCodeBackdrop(props: Props) {
  const { show, onClose } = props;
  const isSmallUp = useIsTabletUp();
  if (isSmallUp) {
    return (
      <SidebarBase open={!!show}>
        <BodySendCode {...props} />
      </SidebarBase>
    );
  }

  return (
    <Backdrop onRequestClose={onClose} show={!!show}>
      {({ onClose }) => {
        return (
          <Box
            sx={{
              padding: "20px",
            }}
          >
            <BodySendCode {...props} onClose={onClose} />
          </Box>
        );
      }}
    </Backdrop>
  );
}
