import Stack from "@mui/material/Stack";
import { useIsMobileOnly, useIsTabletOnly, useTheme } from "../../theme/Theme";
import { Typography } from "@mui/material";
import { ActionButton, ButtonType } from "../ui/ActionButton";
import { DocumentFillIcon } from "../icons/DocumentFillIcon";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../constants/AppRoutes";
import React from "react";

export const ViewSurveyPreview = React.memo(() => {
  const isTablet = useIsTabletOnly();
  const isMobile = useIsMobileOnly();
  const navigate = useNavigate();
  const theme = useTheme();
  const toPreview = () => {
    navigate(AppRoutes.PreviewPersonalDataLink);
  };
  if (isTablet) {
    return (
      <Stack
        sx={{
          boxShadow: "0px 4px 10px 0px #4A51700D",
          padding: "20px 25px",
          marginBottom: "20px",
          borderRadius: "15px",
        }}
      >
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={"16px"}
        >
          <Stack flexDirection={"row"} alignItems={"center"} gap={"19px"}>
            <DocumentFillIcon />
            <Typography variant={"button3"} color={theme.palette.total.main}>
              Ваши данные
            </Typography>
          </Stack>
          <ActionButton
            onClick={toPreview}
            buttonType={ButtonType.Grape}
            sx={{
              width: "187px !important",
              height: "35px !important",
              borderRadius: "12px !important",
            }}
          >
            <Typography variant={"button"} color={theme.palette.const.main}>
              Посмотреть данные
            </Typography>
          </ActionButton>
        </Stack>
        <Typography
          sx={{
            width: "calc(100% - 50px)",
          }}
          variant={"text2"}
          color={theme.palette.gary.main}
        >
          Вы можете проверить данные анкеты и убедиться, что они релевантны. Если заметите
          несоответствие, не стесняйтесь обратиться к нам за помощью.
        </Typography>
      </Stack>
    );
  }
  if (isMobile) {
    return (
      <Stack
        sx={{
          boxShadow: "0px 4px 10px 0px #4A51700D",
          padding: "20px 25px",
          marginBottom: "20px",
          borderRadius: "15px",
        }}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={"19px"}>
          <DocumentFillIcon />
          <Typography variant={"button3"} color={theme.palette.total.main}>
            Ваши данные
          </Typography>
        </Stack>
        <Typography
          mt={"16px"}
          width={"calc(100% - 32px)"}
          variant={"text2"}
          color={theme.palette.gary.main}
        >
          Вы можете проверить данные анкеты и убедиться, что они релевантны. Если заметите
          несоответствие, не стесняйтесь обратиться к нам за помощью.
        </Typography>

        <ActionButton onClick={toPreview} sx={{ marginTop: "16px" }} buttonType={ButtonType.Grape}>
          <Typography variant={"button"} color={theme.palette.const.main}>
            Посмотреть данные
          </Typography>
        </ActionButton>
      </Stack>
    );
  }
  return null;
});
