import { useTheme } from "../../theme/Theme";

export const DocumentFillIcon = () => {
  const theme = useTheme();
  const iconColor = theme.palette.grape.main;

  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.903 0.284752C18.3204 -0.268748 17.3115 0.109253 17.3115 0.878752V5.59025C17.3115 7.56125 19.0736 9.19475 21.2194 9.19475C22.5694 9.20825 24.4452 9.20825 26.0509 9.20825C26.8609 9.20825 27.2873 8.30375 26.7188 7.76375C24.6725 5.80625 21.0062 2.28275 18.903 0.284752Z"
        fill={iconColor}
      />
      <path
        d="M25.5789 11.0565H21.4721C18.1042 11.0565 15.3616 8.451 15.3616 5.2515V1.35C15.3616 0.6075 14.7221 0 13.9405 0H6.68799C2.31115 0 0 1.47273 0 6.29223V20.7078C0 25.5273 2.31115 27 6.68799 27H20.312C24.6889 27 27 25.5273 27 20.7078V12.4065C27 11.664 26.3605 11.0565 25.5789 11.0565ZM12.7895 21.2625H7.10526C6.52263 21.2625 6.03947 20.8035 6.03947 20.25C6.03947 19.6965 6.52263 19.2375 7.10526 19.2375H12.7895C13.3721 19.2375 13.8553 19.6965 13.8553 20.25C13.8553 20.8035 13.3721 21.2625 12.7895 21.2625ZM15.6316 15.8625H7.10526C6.52263 15.8625 6.03947 15.4035 6.03947 14.85C6.03947 14.2965 6.52263 13.8375 7.10526 13.8375H15.6316C16.2142 13.8375 16.6974 14.2965 16.6974 14.85C16.6974 15.4035 16.2142 15.8625 15.6316 15.8625Z"
        fill={iconColor}
      />
    </svg>
  );
};
