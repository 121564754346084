import { DateTime } from "luxon";
import { useProfileQuery } from "../api/profileApi";
import { dateToFormat, SHORT_URL_DATE_FORMAT } from "../utils";

export const getStrategyInitialDates = (INITIAL_MAX_DATE: DateTime) => {
  const STRATEGY_INITIAL_DATES = {
    date: dateToFormat({ date: INITIAL_MAX_DATE, format: SHORT_URL_DATE_FORMAT }),
    toDate: dateToFormat({ date: INITIAL_MAX_DATE, format: SHORT_URL_DATE_FORMAT }),
    fromDate: dateToFormat({
      date: INITIAL_MAX_DATE.minus({ day: 30 }),
      format: SHORT_URL_DATE_FORMAT,
    }),
  };

  return STRATEGY_INITIAL_DATES;
};
export const getPortfolioInitialDates = (INITIAL_MAX_DATE: DateTime) => {
  const PORTFOLIO_INITIAL_DATES = {
    date: dateToFormat({ date: INITIAL_MAX_DATE, format: SHORT_URL_DATE_FORMAT }),
    toDate: dateToFormat({ date: INITIAL_MAX_DATE, format: SHORT_URL_DATE_FORMAT }),
    fromDate: dateToFormat({
      date: INITIAL_MAX_DATE.minus({ day: 30 }),
      format: SHORT_URL_DATE_FORMAT,
    }),
  };
  return PORTFOLIO_INITIAL_DATES;
};
export const getInitialMaxDates = (days: number) => {
  return DateTime.now().minus({ day: days });
};
export default function useDate() {
  const { data } = useProfileQuery(undefined);
  const intervalDays = data?.data?.intervalDays ?? 3;
  const INITIAL_MAX_DATE = DateTime.now().minus({ day: intervalDays });
  const INITIAL_TODAY_DATE = DateTime.now();
  const BRIEFCASE_PAGE_INITIAL_QUERY = {
    date: getStrategyInitialDates(INITIAL_MAX_DATE).date,
    toDate: getStrategyInitialDates(INITIAL_MAX_DATE).toDate,
    fromDate: getStrategyInitialDates(INITIAL_MAX_DATE).fromDate,
    successBuyStrategy: "",
  };

  return {
    INITIAL_MAX_DATE,
    INITIAL_TODAY_DATE,
    PORTFOLIO_INITIAL_DATES: getPortfolioInitialDates(getInitialMaxDates(intervalDays)),
    STRATEGY_INITIAL_DATES: getStrategyInitialDates(getInitialMaxDates(intervalDays)),
    BRIEFCASE_PAGE_INITIAL_QUERY,
  };
}
