import React, { useMemo } from "react";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { object, string } from "yup";
import Typography from "@mui/material/Typography";

import { Loader } from "../ui/Loader";
import { CodeField } from "../form/CodeField";
import { makeStyles } from "../../theme/Theme";
import { PhoneField } from "../form/PhoneField";
import { ActionButton } from "../ui/ActionButton";
import { AuthFormButton } from "./AuthFormButton";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { isValidPhone } from "../../utils/ValidateUtils";
import { AuthFormHeaderProps } from "./AuthFormHeader";
import { ButtonBaseProps } from "@mui/material";
import { QRCodeAuth } from "../QRCodeAuth/QRCodeAuth";
import { Sidebar } from "../sidebar/Sidebar";
import { hidePhoneNumbers } from "../../helpers/FormatHelpers";

const validationSchema = object({
  phone: string().test({
    test: (value) => isValidPhone(value),
    message: "Введите номер телефона",
  }),
});

const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: "15px !important",
    alignSelf: "center",
  },
  agreement: {
    textAlign: "left",
    whiteSpace: "pre-line",
    color: theme.palette.gary.main,
    marginTop: 30,
  },
  btnSendCode: {
    marginTop: "15px !important",
  },
  agreementActiveLink: {
    color: theme.palette.total.main,
    textAlign: "left",
    textDecoration: "underline",
    whiteSpace: "pre-line",
    cursor: "pointer",
  },
  phoneFieldWrapper: {
    marginBottom: "15px !important",
  },
  wrapperAgreement: {
    [theme.breakpoints.only(Breakpoints.Mobile)]: {
      marginTop: "15px !important",
    },
    marginTop: "30px !important",
    marginBottom: "30px !important",
  },
}));

const INITIAL_VALUES = { phone: "", code: "" };

export interface AuthFormProps extends Omit<AuthFormHeaderProps, "title"> {
  readonly onAgreementClick?: ButtonBaseProps["onClick"];
  readonly onConsentProcessingPersonalData?: ButtonBaseProps["onClick"];
  readonly onPrivacyPolicy?: ButtonBaseProps["onClick"];
  phone?: string;
  setPhone: (phone: string | undefined) => void;
  readonly onSubmit: (values: Auth.AuthenticateBodyProps) => Promise<void>;
}

export function AuthForm({
  onSubmit,
  phone,
  setPhone,
  onBackClick,
  onAgreementClick,
  onPrivacyPolicy,
  onConsentProcessingPersonalData,
}: AuthFormProps) {
  const classes = useStyles();

  const formattedPhoneText = useMemo(() => {
    if (phone) {
      return "Мы отправили СМС с кодом на Ваш номер: \n" + hidePhoneNumbers(phone) || "";
    }
    return "";
  }, [phone]);

  return (
    <>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={(values, helpers) =>
          onSubmit(values).catch((error) => {
            const fieldName = phone ? "code" : "phone";

            helpers.setFieldError(fieldName, error.message);
          })
        }
      >
        {({ values, isSubmitting, handleSubmit, setFieldValue, setTouched, setFieldTouched }) => {
          const submitHandler = (e) => {
            e.preventDefault();
            setTouched({
              phone: true,
            });

            return handleSubmit();
          };
          return (
            <Form onSubmit={submitHandler}>
              {!phone && (
                <>
                  <Stack>
                    <Stack className={classes.phoneFieldWrapper}>
                      <PhoneField
                        name="phone"
                        inputProps={{
                          placeholder: "Телефон",
                          id: "AuthFormPhoneField",
                        }}
                      />
                    </Stack>
                    <ActionButton loading={isSubmitting} onClick={submitHandler}>
                      <Typography variant={"button"}>Продолжить</Typography>
                    </ActionButton>
                  </Stack>
                  <Stack className={classes.wrapperAgreement}>
                    <Typography variant={"button-filled"} className={classes.agreement}>
                      Продолжая, вы соглашаетесь с условиями{" "}
                      <span onClick={onAgreementClick} className={classes.agreementActiveLink}>
                        Пользовательского соглашения
                      </span>
                      ,{" "}
                      <span onClick={onPrivacyPolicy} className={classes.agreementActiveLink}>
                        Политики конфиденциальности
                      </span>{" "}
                      и даёте{" "}
                      <span
                        onClick={onConsentProcessingPersonalData}
                        className={classes.agreementActiveLink}
                      >
                        согласие
                      </span>{" "}
                      на обработку персональных данных.
                    </Typography>
                  </Stack>
                  <QRCodeAuth />
                </>
              )}
              <Sidebar
                open={!!phone}
                subTitle={formattedPhoneText}
                title={"Введите код из СМС"}
                onClose={() => {
                  setFieldValue("code", "");
                  setPhone(undefined);
                  onBackClick();
                }}
              >
                {({ onClose }) => {
                  return (
                    <Stack>
                      <CodeField
                        name="code"
                        inputProps={{
                          autoFocus: true,
                          onComplete: () => {
                            handleSubmit();
                          },
                        }}
                      />
                      {!isSubmitting && (
                        <AuthFormButton
                          type="submit"
                          className={classes.btnSendCode}
                          phone={phone || ""}
                          loading={isSubmitting}
                          onClick={submitHandler}
                        />
                      )}
                      {isSubmitting && <Loader className={classes.loader} />}
                    </Stack>
                  );
                }}
              </Sidebar>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
