import cx from "classnames";
import ButtonBase from "@mui/material/ButtonBase";
import { Transition } from "react-transition-group";
import { ReactNode } from "react";

import { ModalBase } from "../ui/ModalBase";
import { makeStyles } from "../../theme/Theme";
import { useQuery } from "../../hooks/useQuery";
import { useDimensions } from "../../hooks/useDimensions";
import { isFunction, noop } from "lodash";
import { Box, BoxProps } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  overlay: {
    "&.MuiButtonBase-root": {
      flex: 1,
      opacity: 0,
      transition: "opacity 270ms ease-in-out",
      backgroundColor: theme.palette.common.black,
    },
  },
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    pointerEvents: "none",
    flexDirection: "column",
    justifyContent: "flex-end",
    transition: "transform 270ms ease-in-out",
  },
  handler: {
    opacity: 0.5,
    alignSelf: "center",
    backgroundColor: "#ffffff",
    height: theme.typography.pxToRem(5),
    width: theme.typography.pxToRem(134),
    marginBottom: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(100),
  },
  content: {
    overflowY: "auto",
    overflowX: "hidden",
    pointerEvents: "all",
    backgroundColor: theme.palette.walter.main,
    borderTopLeftRadius: theme.typography.pxToRem(34),
    borderTopRightRadius: theme.typography.pxToRem(34),
  },
}));

interface ChildrenFunctionProps {
  readonly onClose: () => void;
}

type ChildrenFunctionType = (props: ChildrenFunctionProps) => ReactNode;

export interface BackdropProps {
  readonly show: boolean;
  readonly onRequestClose: () => void;
  readonly children: ChildrenFunctionType | ReactNode;
  readonly contentProps?: BoxProps;
  readonly outsideClickClose?: boolean;
}

export function Backdrop({
  show,
  contentProps,
  children,
  onRequestClose,
  outsideClickClose = true,
}: BackdropProps) {
  const classes = useStyles();
  const dimensions = useDimensions();

  const overlayStyles = {
    exited: { opacity: 0 },
    exiting: { opacity: 0 },
    entered: { opacity: 0.7 },
    entering: { opacity: 0.7 },
  };

  const backdropStyles = {
    exited: { transform: `translateY(${dimensions.height}px)` },
    exiting: { transform: `translateY(${dimensions.height}px)` },
    entered: { transform: "translateY(20px)" },
    entering: { transform: "translateY(20px)" },
  };

  useQuery();

  if (!show) {
    return null;
  }

  return (
    // @ts-ignore
    <ModalBase>
      {/*@ts-ignore*/}
      <Transition in={show} timeout={270} onExited={onRequestClose}>
        {/*@ts-ignore*/}
        {(state) => (
          <>
            <ButtonBase
              disableRipple={true}
              onClick={outsideClickClose ? onRequestClose : noop}
              className={classes.overlay}
              style={overlayStyles[state]}
            />

            <div className={classes.container} style={backdropStyles[state]}>
              <div className={classes.handler} />

              {!isFunction(children) && (
                <Box {...contentProps} className={cx(classes.content, "hide-scrollbar")}>
                  {children}
                </Box>
              )}
              {isFunction(children) && (
                <Box {...contentProps} className={cx(classes.content, "hide-scrollbar")}>
                  {children({ onClose: onRequestClose })}
                </Box>
              )}
            </div>
          </>
        )}
      </Transition>
    </ModalBase>
  );
}
