import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles, useTheme } from "../../theme/Theme";
import {
  placeholderRegistrationFields,
  StepDataFieldsValid,
  stepDataRegistrations,
  useRegistration,
} from "../../hooks/useRegistration";
import classNames from "classnames";
import { ActionButton } from "../ui/ActionButton";
import { TextInput } from "../ui/TextInput";
import { useEffect, useMemo, useState } from "react";
import { AuthLineProgressItem } from "./AuthLineProgressItem";

const useStyles = makeStyles((theme) => ({
  stepText: {
    color: theme.palette.gary.main,
    marginBottom: "15px !important",
  },
  title: {
    color: theme.palette.total.main,
    whiteSpace: "pre-wrap",
  },
  root: {
    translation: "all 0.5s",
  },
  wrapperLine: {
    marginBottom: 15,
    gap: 15,
  },
  form: {
    marginTop: 30,
    width: "100%",
  },
  wrapperInput: {
    gap: 12,
    display: "flex",
    marginTop: "15px !important",
    flexDirection: "column",
  },
  input: {
    width: "100%",
  },
  btn: {
    width: "100%",
    marginTop: "20px !important",
  },
  titleForm: {
    color: theme.palette.gary.main,
  },
  "@keyframes fadeText": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  fade: {
    animation: `$fadeText 0.5s ${theme.transitions.easing.easeInOut}`,
  },
}));

export const ProgressStepSaveStepUser = () => {
  const styles = useStyles();
  const { currentStepData: step, nextStep, changeDataUser, dataUser } = useRegistration();
  const [isFade, setIsFade] = useState(false);
  const theme = useTheme();
  const changeData = (value: string, nameField: keyof Auth.UserInfoSave) => {
    changeDataUser(value, nameField);
  };
  const isDisabledButton = useMemo(() => {
    if (!dataUser) return true;
    return !step.fields.every((nameField) => {
      const value = dataUser[nameField];
      const isFieldRequired = step?.fieldsValid ? step?.fieldsValid[nameField]?.required : false;

      // @ts-ignore
      const optionValid = step?.fieldsValid[nameField] as StepDataFieldsValid;

      if (optionValid?.validFn && !optionValid.validFn(value || "")) return false;

      if (optionValid.len && value && value.length !== optionValid.len) return false;

      if (!isFieldRequired) return true;
      if (!value || value.trim() === "") return false;
      return !(optionValid.len && value.length !== optionValid.len);
    });
  }, [dataUser, step.fields, step?.fieldsValid]);
  useEffect(() => {
    setIsFade(true);
    const timeout = setTimeout(() => {
      setIsFade(false);
    }, 600);
    return () => {
      clearTimeout(timeout);
    };
  }, [step.stepNumber]);
  return (
    <Stack className={styles.root}>
      <Typography className={styles.stepText} variant={"text2"}>
        Шаг <span className={classNames({ [styles.fade]: isFade })}>{step.stepNumber}</span> из{" "}
        {stepDataRegistrations.length}.
      </Typography>
      <Stack flexDirection={"row"} className={styles.wrapperLine}>
        {Array(stepDataRegistrations.length)
          .fill(1)
          .map((_, index) => (
            <AuthLineProgressItem isActive={index + 1 <= step.stepNumber} key={index} />
          ))}
      </Stack>
      <Typography
        color={theme.palette.total.main}
        className={classNames(styles.title, { [styles.fade]: isFade })}
        variant={"text2"}
      >
        {step.title}
      </Typography>
      <Box className={styles.form}>
        <Typography
          className={classNames(styles.titleForm, { [styles.fade]: isFade })}
          variant={"text2"}
        >
          {step.formTitle}
        </Typography>
        <Box className={classNames(styles.wrapperInput, { [styles.fade]: isFade })}>
          {step.fields?.map((field) => {
            const propsForInput = step?.optionsFields ? step!.optionsFields[field] : {};
            return (
              <TextInput
                onChange={(event) => {
                  changeData(event.target.value, field);
                }}
                key={field as string}
                value={dataUser && dataUser[field]}
                className={classNames(styles.input, { [styles.fade]: isFade })}
                placeholder={placeholderRegistrationFields[field]}
                name={field as string}
                {...propsForInput}
              />
            );
          })}
        </Box>
        <ActionButton disabled={isDisabledButton} onClick={nextStep} className={styles.btn}>
          <Typography color={theme.palette.const.main} variant={"button"}>
            Продолжить
          </Typography>
        </ActionButton>
      </Box>
    </Stack>
  );
};
