import { Stack } from "@mui/material";
import { PlaceholderBox } from "../placeholder/PlaceholderBox";

export const SkeletonDocItems = () => {
  return (
    <Stack>
      {Array.from({ length: 3 }).map((_, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          mt={index !== 0 ? "12px" : "0px"}
          gap={"16px"}
        >
          <PlaceholderBox width={24} height={24} sx={{ borderRadius: "5px" }} />
          <PlaceholderBox height={16} sx={{ width: "100%", maxWidth: "322px" }} />
        </Stack>
      ))}
    </Stack>
  );
};
