import { DateTime } from "luxon";
import { Period } from "../dto/FundsDTO";
import {
  dateFromFormat,
  FULL_DATE_JOURNAL_OPERATIONS_FORMAT,
  normalizeDateTime,
  SHORT_DATE_FORMAT,
} from "../utils";

export function formatMonthTitle(month: number): string {
  switch (month) {
    case 1:
      return "Январь";
    case 2:
      return "Февраль";
    case 3:
      return "Март";
    case 4:
      return "Апрель";
    case 5:
      return "Май";
    case 6:
      return "Июнь";
    case 7:
      return "Июль";
    case 8:
      return "Август";
    case 9:
      return "Сентябрь";
    case 10:
      return "Октябрь";
    case 11:
      return "Ноябрь";
    case 12:
      return "Декабрь";

    default:
      return "";
  }
}

/** Return array of years in closed interval [minDate, maxDate] */
export function getYearsList(minDate: DateTime, maxDate: DateTime) {
  const year = minDate.year;
  const numberYears = maxDate.year - minDate.year + 1;

  return Array(numberYears)
    .fill(1)
    .map((_, idx) => idx + year);
}

export function calculateNextRegularDate(date: string, period: Common.PeriodType): DateTime {
  const nowDateTime = DateTime.now();
  const dateTime = normalizeDateTime(date);

  switch (period) {
    case Period.HalfYear: {
      const diff = nowDateTime.diff(dateTime, "months");

      const nextDateTime = dateTime.plus({ month: Math.max(Math.ceil(diff.months), 6) });

      if (nextDateTime.toMillis() > nowDateTime.toMillis()) {
        return nextDateTime;
      }

      return nextDateTime.set({ month: nowDateTime.month + 1 });
    }
    case Period.Week: {
      const diff = nowDateTime.diff(dateTime, "weeks");

      const nextDateTime = dateTime.plus({ week: Math.ceil(diff.weeks) });

      if (nextDateTime.toMillis() > nowDateTime.toMillis()) {
        return nextDateTime;
      }

      return nextDateTime.set({ weekNumber: nextDateTime.weekNumber + 1 });
    }
    case Period.Month: {
      const nextDateTime = dateTime.set({ month: nowDateTime.month });

      if (nextDateTime.toMillis() > nowDateTime.toMillis()) {
        return nextDateTime;
      }

      return nextDateTime.plus({ month: 1 });
    }

    default:
      return nowDateTime.plus({ day: 1 });
  }
}

export function formatDate(date: string | undefined) {
  if (!date) return "";
  if (date.split("T").length > 1) {
    return normalizeDateTime(date).toFormat(SHORT_DATE_FORMAT);
  }
  return dateFromFormat({
    date: date,
    toFormat: SHORT_DATE_FORMAT,
    fromFormat: FULL_DATE_JOURNAL_OPERATIONS_FORMAT,
  });
}
