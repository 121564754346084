import { noop } from "lodash";
import { createContext, ReactNode, useContext, useState } from "react";
import { changeCode } from "../../slices/authSlice";
import { changeModeUser } from "../../slices/applicationSlice";
import { useDispatch } from "react-redux";
export interface EnterInSystemType {
  code: string;
  phone: string;
  modeUser: Store.modeUser;
}
interface initialStateType {
  changeStep: (step: number) => void;
  step: number;
  dataUser: Partial<Auth.UserInfoSave> | undefined;
  changeDataUser: (value: string, fieldName: keyof Auth.UserInfoSave) => void;
  isOpenConfirmMail: boolean;
  setIsOpenConfirmMail: (value: boolean) => void;
  clearDataUser: () => void;
  regPhone: string | undefined;
  setRegPhone: (phone: string | undefined) => void;
  enterInSystem: (data: EnterInSystemType) => void;
}

const initialState: initialStateType = {
  changeStep: noop,
  enterInSystem: noop,
  step: 1,
  dataUser: undefined,
  changeDataUser: noop,
  isOpenConfirmMail: false,
  setIsOpenConfirmMail: noop,
  clearDataUser: noop,
  regPhone: "",
  setRegPhone: noop,
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [step, setStep] = useState(0);
  const [dataUser, setDataUser] = useState<Partial<Auth.UserInfoSave> | undefined>(undefined);
  const [isOpenConfirmMail, setIsOpenConfirmMail] = useState<boolean>(false);
  const [regPhone, setRegPhone] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  const changeInfoUser = (value: string, fieldName: keyof Auth.UserInfoSave) => {
    setDataUser((prev) => {
      return {
        ...prev,
        [fieldName]: value,
      };
    });
  };
  const clearDataUser = () => {
    setDataUser(undefined);
  };

  const enterInSystem = ({ code, modeUser }: EnterInSystemType) => {
    dispatch(changeCode(code));
    dispatch(changeModeUser(modeUser));
  };

  return (
    <AuthContext.Provider
      value={{
        changeStep: setStep,
        step,
        enterInSystem,
        dataUser,
        changeDataUser: changeInfoUser,
        isOpenConfirmMail,
        setIsOpenConfirmMail,
        clearDataUser,
        regPhone,
        setRegPhone,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
