import { createApi } from "@reduxjs/toolkit/query/react";

import { createUrl } from "../utils/UrlUtils";
import { ApiRoutes } from "../constants/ApiRoutes";
import { fetchQuery } from "../helpers/ApiHelpers";
enum RegistrationTagsApi {
  NewInfoUser = "NewInfoUser",
}
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchQuery(),
  tagTypes: [RegistrationTagsApi.NewInfoUser],
  endpoints: (builder) => ({
    sendCode: builder.mutation<Auth.SendCodeResponseProps, Auth.AuthenticateBodyProps>({
      query: (query) => createUrl(ApiRoutes.AuthSendCode, { query }),
      extraOptions: { withSignature: false },
      // @ts-ignore
      transformResponse: ({ sessionid }) => ({ sessionId: sessionid }),
    }),
    checkCode: builder.mutation<
      Application.ResponseProps<Auth.CheckCodeResponseProps>,
      Auth.AuthenticateBodyProps
    >({
      query: (query) => createUrl(ApiRoutes.AuthCheckCode, { query }),
    }),
    logout: builder.mutation<void, void>({ query: () => ApiRoutes.Logout }),
    registrationSaveData: builder.mutation<void, Auth.UserInfoSave>({
      query: (data) => ({
        method: "POST",
        url: ApiRoutes.RegistrationSavePersonalData,
        body: data,
      }),
    }),
    registrationConfirm: builder.mutation<void, { code: string }>({
      query: (data) => createUrl(ApiRoutes.RegistrationConfirm, { query: data }),
    }),
    registrationGenerate: builder.mutation<void, void>({
      query: () => ApiRoutes.RegistrationGenerate,
    }),
    registrationConfirmEmail: builder.mutation<void, Auth.ConfirmEmail>({
      query: (data) => {
        return createUrl(ApiRoutes.RegistrationConfirmEmail, { query: data });
      },
    }),
  }),
});
export const {
  useSendCodeMutation,
  useLogoutMutation,
  useCheckCodeMutation,
  useRegistrationSaveDataMutation,
  useRegistrationConfirmEmailMutation,
  useRegistrationGenerateMutation,
  useRegistrationConfirmMutation,
} = authApi;
