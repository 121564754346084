import React from "react";
import Typography from "@mui/material/Typography";
import ButtonBase, { ButtonBaseProps } from "@mui/material/ButtonBase";

import { makeStyles } from "../../theme/Theme";

const useStyles = makeStyles((theme) => ({
  title: {
    textDecoration: "underline",
    color: theme.palette.cow.main,
  },
}));

interface Props extends Omit<ButtonBaseProps, "children"> {
  readonly title: string;
}

export function LinkButton({ title, ...props }: Props) {
  const classes = useStyles();

  return (
    <ButtonBase disableRipple={true} {...props}>
      <Typography variant="link1" className={classes.title}>
        {title}
      </Typography>
    </ButtonBase>
  );
}
