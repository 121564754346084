import cx from "classnames";
import React, { ReactNode, useMemo } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from "@mui/material/Checkbox";

import { CheckboxIcon } from "./CheckboxIcon";
import { makeStyles } from "../../theme/Theme";
import { isString } from "lodash";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    "&.MuiCheckbox-root": {
      padding: 0,
      marginRight: theme.typography.pxToRem(16),
      "&:hover": {
        backgroundColor: "unset",

        "& > .checkbox-icon": {
          boxShadow: theme.customShadows.hover,
        },
      },
    },
  },
}));

export interface CheckboxProps extends MuiCheckboxProps {
  readonly error?: boolean;
  readonly label?: ReactNode;
  readonly rootClassName?: string;
}

export function Checkbox({
  label: propLabel,
  disabled,
  error,
  rootClassName,
  ...props
}: CheckboxProps) {
  const classes = useStyles();

  const label = useMemo(() => {
    if (isString(propLabel)) {
      return <Typography variant="text2">{propLabel}</Typography>;
    }

    return propLabel;
  }, [propLabel]);

  return (
    <FormControlLabel
      label={label}
      sx={{
        margin: 0,
      }}
      className={rootClassName}
      disabled={disabled}
      control={
        <MuiCheckbox
          {...props}
          disabled={disabled}
          className={cx(classes.checkbox, props.className)}
          icon={<CheckboxIcon error={error} />}
          checkedIcon={<CheckboxIcon active={true} error={error} />}
        />
      }
    />
  );
}
