import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/configureStore";
interface InitialState {
  values: {
    [key: string]: Survey.AnswerPrev;
  };
}

interface SaveValueBySurvey {
  key: string;
  data: Survey.AnswerPrev;
}
const initialState: InitialState = {
  values: {},
};
export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    resetAll: (state) => {
      state.values = {};
    },
    saveSurveyFields: (state, action: PayloadAction<SaveValueBySurvey>) => {
      const { key, data } = action.payload;
      state.values[key] = data;
    },
  },
});

export const { saveSurveyFields } = surveySlice.actions;

export function getValuesBySurveySelector(
  key: string,
): (state: RootState) => Survey.AnswerPrev | undefined {
  return ({ survey }) => {
    return survey.values[key] || undefined;
  };
}
