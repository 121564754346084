import React from "react";
import cx from "classnames";

import { SuccessCheckmarkIcon } from "../icons/SuccessCheckmarkIcon";
import { makeStyles } from "../../theme/Theme";

interface StylesProps {
  readonly error?: boolean;
  readonly active?: boolean;
}

const useStyles = makeStyles<StylesProps>((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    borderRadius: theme.typography.pxToRem(4),
    border: (props) => {
      if (props.error) {
        return `${theme.typography.pxToRem(2)} ${theme.palette.sparrow.main} solid`;
      }

      if (props.active) {
        return `${theme.typography.pxToRem(2)} ${theme.palette.bloom.main} solid`;
      }

      return `${theme.typography.pxToRem(2)} ${theme.palette.grape.main} solid`;
    },
    backgroundColor: (props) => {
      if (props.error && props.active) {
        return theme.palette.sparrow.main;
      }

      if (props.active) {
        return theme.palette.bloom.main;
      }

      return "transparent";
    },
  },
}));

interface Props {
  readonly error?: boolean;
  readonly active?: boolean;
}

export function CheckboxIcon({ error, active }: Props) {
  const classes = useStyles({ error, active });

  return (
    <div className={cx(classes.root, "checkbox-icon")}>
      <SuccessCheckmarkIcon color={active ? "white" : "transparent"} />
    </div>
  );
}
