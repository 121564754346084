import { AnyAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authApi } from "../api/authApi";
import { commonApi } from "../api/commonApi";
import { surveyApi } from "../api/surveyApi";
import { profileApi } from "../api/profileApi";
import { strategyApi } from "../api/strategyApi";
import { settingsApi } from "../api/settingsApi";
import { briefcaseApi } from "../api/briefcaseApi";
import { manageFundsApi } from "../api/manageFundsApi";

import auth, { authReducerPersistConfig } from "../slices/authSlice";
import profile, { profileReducerPersistConfig } from "../slices/profileSlice";
import briefcase, { briefcaseReducerPersistConfig } from "../slices/briefcaseSlice";
import application, { applicationReducerPersistConfig } from "../slices/applicationSlice";
import { operationsJournalApi } from "../api/operationsJournalApi";
import { replenishSlice } from "../slices/replenishSlice";
import { requisiteApi } from "../api/requisiteApi";
import { qrCodeApi } from "../api/qrCodeApi";
import { notificationSlice } from "../slices/notificationSlice";
import { surveySlice } from "../slices/surveySlice";

export const rootReducer = {
  application: persistReducer<Store.AppReducerState, AnyAction>(
    {
      ...applicationReducerPersistConfig,
      key: "application",
      storage,
    },
    application,
  ),
  profile: persistReducer<Store.ProfileReducerState, AnyAction>(
    {
      ...profileReducerPersistConfig,
      key: "profile",
      storage,
    },
    profile,
  ),
  auth: persistReducer<Store.AuthReducerState, AnyAction>(
    {
      ...authReducerPersistConfig,
      key: "auth",
      storage,
    },
    auth,
  ),
  briefcase: persistReducer<Store.BriefcaseReducerState, AnyAction>(
    {
      ...briefcaseReducerPersistConfig,
      key: "briefcase",
      storage,
    },
    briefcase,
  ),
  [surveySlice.name]: surveySlice.reducer,
  [replenishSlice.name]: replenishSlice.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  // Api
  [authApi.reducerPath]: authApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [requisiteApi.reducerPath]: requisiteApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [strategyApi.reducerPath]: strategyApi.reducer,
  [qrCodeApi.reducerPath]: qrCodeApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [briefcaseApi.reducerPath]: briefcaseApi.reducer,
  [manageFundsApi.reducerPath]: manageFundsApi.reducer,
  [operationsJournalApi.reducerPath]: operationsJournalApi.reducer,
};
