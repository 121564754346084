import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles, useTheme } from "../../theme/Theme";
import { LinkButton } from "../buttons/LinkButton";
import { AppRoutes } from "../../constants/AppRoutes";
import { Breakpoints } from "../../dto/ApplicationDTO";

const useStyles = makeStyles((theme) => ({
  infoButton: {
    "&.MuiButtonBase-root": {
      marginBottom: theme.typography.pxToRem(16),
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.typography.pxToRem(40),
    paddingBottom: theme.typography.pxToRem(40),
    [theme.breakpoints.down(Breakpoints.Tablet)]: {
      paddingBottom: theme.typography.pxToRem(24),
    },

    borderTop: `1px solid ${theme.palette.grape.main}`,
  },
}));

export function AppLayoutFooter() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const toRequiredInfo = () => {
    navigate(AppRoutes.DashboardBriefcaseRequiredInfoLink);
  };

  return (
    <div className={classes.infoContainer}>
      <div>
        <LinkButton
          title="Обязательная информация"
          onClick={toRequiredInfo}
          className={classes.infoButton}
        />
      </div>
      <Typography color={theme.palette.gary.main} variant="text4">
        © АО “ФБ “Август”, 1995-{DateTime.now().year}
      </Typography>
    </div>
  );
}
