import React from "react";
import { makeStyles } from "../../theme/Theme";
import cx from "classnames";
import { Box, BoxProps } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1280,
    width: "100%",
    margin: "0 auto",
  },
}));

export function AppLayoutContent({ children, className, ...props }: BoxProps) {
  const classes = useStyles();

  return (
    <Box {...props} className={cx(classes.root, className)}>
      {children}
    </Box>
  );
}
