import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "../../theme/Theme";
import { AppRoutes } from "../../constants/AppRoutes";
import { Breakpoints } from "../../dto/ApplicationDTO";
import { AuthPromo } from "../../components/auth/AuthPromo";
import { AuthSidebar } from "../../components/auth/AuthSidebar";
import { formatAuthenticateBody } from "../../helpers/AuthHelpers";
import { useCheckCodeMutation, useSendCodeMutation } from "../../api/authApi";
import { changeCode, changeSessionId } from "../../slices/authSlice";
import { useRegistration } from "../../hooks/useRegistration";
import { useLazyGetErrorByStrategyQuery } from "../../api/strategyApi";
import { useAuth } from "../../components/auth/AuthContext";
import { changeModeUser } from "../../slices/applicationSlice";
import {
  ActionDocumentMov,
  BuyStrategyDataProps,
} from "../../components/document/DocumentLookContractByStrategy";
import { createAnswerForSend } from "../../helpers/SurveyHelpers";
import { createUrl } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.flexFull,
    ...theme.mixins.flexColumn,
  },
  content: {
    ...theme.mixins.flexFull,
    [theme.breakpoints.down(Breakpoints.Tablet)]: { flexDirection: "column-reverse" },
  },
}));

export function AuthPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { beginInputPersonalData } = useRegistration();
  const { enterInSystem } = useAuth();
  const [getErrorByStrategy] = useLazyGetErrorByStrategyQuery();

  const [sendCodeMutation] = useSendCodeMutation();
  const [checkCodeMutation] = useCheckCodeMutation();

  const [authPhone, setAuthPhone] = useState<string | undefined>();

  const enterCurrentUser = async (values: Auth.AuthenticateBodyProps, isDemo: Store.modeUser) => {
    setAuthPhone(values.phone);
    enterInSystem({
      code: values.code,
      phone: values.phone,
      modeUser: isDemo,
    });
    // eslint-disable-next-line promise/no-nesting
    return await getErrorByStrategy()
      .then((data) => {
        if (data?.data?.data?.idStrategy) {
          navigate(AppRoutes.ProfileLink);
          return;
        }
        navigate(AppRoutes.DashboardBriefcaseLink);
      })
      .catch(() => {
        navigate(AppRoutes.DashboardBriefcaseLink);
      });
  };
  const submitHandler = (values: Auth.AuthenticateBodyProps) => {
    if (values.code) {
      return checkCodeMutation(formatAuthenticateBody(values))
        .unwrap()
        .then(async (data) => {
          const responseCheckCode = data?.data;
          dispatch(changeCode(values.code));
          if (responseCheckCode?.statusUser === "cold") {
            dispatch(changeModeUser("cold"));
            if (responseCheckCode?.checkPointBuyStrategy) {
              switch (responseCheckCode.checkPointBuyStrategy) {
                case "personalData":
                  setAuthPhone(values.phone);
                  beginInputPersonalData(values.phone);
                  break;
                case "oneSurvey":
                  navigate(AppRoutes.SurveyBeforeBuyStrategyLink);
                  break;
                case "twoSurvey":
                  navigate(
                    createUrl(AppRoutes.SurveyAfterBuyStrategyLink, {
                      query: { strategyId: data.data?.strategyId ?? "" },
                    }),
                  );
                  break;
                case "treaty":
                  const beforeFields = await createAnswerForSend("SurveyTestingBeforeBuyStrategy");
                  const afterFields = await createAnswerForSend("SurveyTestingAfterBuyStrategy");
                  const dataForTreaty: BuyStrategyDataProps = {
                    type: ActionDocumentMov.BUY_STRATEGY,
                    strategyId: data.data?.strategyId ?? "",
                    routeBack: AppRoutes.SurveyAfterBuyStrategyLink,
                    stateData: {
                      strategyId: data.data?.strategyId ?? "",
                      stepId: afterFields.stepId,
                      fields: [...beforeFields.fields, ...afterFields.fields],
                    },
                  };
                  navigate(AppRoutes.DocumentRiskStrategyLink, { state: dataForTreaty });
                  break;
                default:
                  navigate(AppRoutes.DashboardBriefcaseLink);
                  break;
              }
              return;
            }
            // await registrationInfoUser()
            //   .unwrap()
            //   .then((dataInfoUser) => {
            //     if (dataInfoUser?.data?.checkPointSaveInfo === "survey") {
            //       setAuthPhone(values.phone);
            //       enterInSystem({
            //         code: values.code,
            //         phone: values.phone,
            //         modeUser: "hot",
            //       });
            //       toHotUserSurvey(dataInfoUser.data.idTargetStrategy);
            //     } else if (dataInfoUser?.data?.checkPointSaveInfo === "personal data") {
            //       enterInSystem({
            //         code: values.code,
            //         phone: values.phone,
            //         modeUser: "cold",
            //       });
            //       setAuthPhone(values.phone);
            //       beginInputPersonalData(values.phone);
            //     } else {
            //       enterCurrentUser(values, "cold");
            //     }
            //   })
            //   .catch(openSnackbarError);
            // return;
            return await getErrorByStrategy()
              .then((data) => {
                if (data?.data?.data?.idStrategy) {
                  navigate(AppRoutes.ProfileLink);
                  return;
                }
                navigate(AppRoutes.DashboardBriefcaseLink);
              })
              .catch(() => {
                navigate(AppRoutes.DashboardBriefcaseLink);
              });
          }
          return enterCurrentUser(values, "simple");
        });
    }

    return sendCodeMutation(formatAuthenticateBody(values))
      .unwrap()
      .then((x) => {
        if (x?.sessionId) {
          dispatch(changeSessionId(x.sessionId));
          setAuthPhone(values.phone);
        }
      });
  };
  const toAgreementClick = () => {
    window.open(`${process.env.REACT_APP_API_HOST}/api/public/agreement/download`);

    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Пользовательское соглашение" },
    // });
  };
  const toPrivacyPolicy = () => {
    window.open("https://www.fbaugust.ru/upload/iblock/33b/4qe11520fodb5aix95yhqs6pibpv4myr.txt");

    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Политика конфиденциальности" },
    // });
  };
  const toConsentProcessingPersonalData = async () => {
    window.open("https://be.fbaugust.ru:2841/api/agreement_web");
    // navigate(AppRoutes.StartAgreement, {
    //   state: { text: textAgreement, title: "Согласие на обработку персональных данных" },
    // });
  };

  useEffect(() => {
    dispatch(changeModeUser("simple"));
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <AuthPromo />

        <AuthSidebar
          phone={authPhone}
          setPhone={setAuthPhone}
          onSubmit={submitHandler}
          onBackClick={() => setAuthPhone(undefined)}
          onAgreementClick={toAgreementClick}
          onConsentProcessingPersonalData={toConsentProcessingPersonalData}
          onPrivacyPolicy={toPrivacyPolicy}
        />
      </div>
    </div>
  );
}
