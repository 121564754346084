function extractBase64FromDataURI(dataURI: string) {
  // Разделение строки Data URI по запятой
  const parts = dataURI.split(",");

  // Проверка наличия двух частей после разделения
  if (parts.length === 2) {
    // Если нашли две части, возвращаем вторую часть, содержащую Base64 данные
    return parts[1];
  } else {
    // Если не удалось разделить строку, возвращаем пустую строку
    return "";
  }
}

export function base64ToFile(base64Url: string, filename: string): File | null {
  const arr = base64Url.split(",");
  const mimeMatch = arr[0].match(/:(.*?);/);

  if (!mimeMatch) return null;

  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
const isUrlWithBase64 = (file: File | { url: string }): file is { url: string } => {
  return file && "url" in file && typeof file?.url === "string" && file?.url.includes("base64");
};
export function getFileDataInBase64(
  file: File | { url: string },
  withType?: boolean,
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (isUrlWithBase64(file)) {
      resolve(file.url);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      const base64 = extractBase64FromDataURI(e.target?.result as string);
      if (withType) {
        resolve(`data:${file.type};base64,${base64}`);
        return;
      }
      resolve(base64);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

export function getBase64ListFromFiles(files: File[]): Promise<string[]> {
  return Promise.all(files.map((file) => getFileDataInBase64(file, true)));
}

export const base64ToImagePng = (base64: string) => `data:image/png;base64,${base64}`;
